import { gql } from "@apollo/client";

const VERIFY_AC_USER_MUTATION
 = gql`
  mutation verifyACUserMutation($clientMutationId: String!, $token:String! ){
    verifyACUser(input: {clientMutationId: $clientMutationId, token:$token}) {
      data
      success
    }
  }
`;

export default VERIFY_AC_USER_MUTATION